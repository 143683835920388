// External Imports
import React, { Fragment } from "react";
import { Grid } from "@mui/material";
// Components
import {
  BlogPostCard,
  SkeletonCard,
} from "../../../exports/components.exports";
import { urlRoutes } from "../../../exports/constants.exports";

/**
 * @function AllBlogs
 * @returns the blogs grid item where a skeleton is returned if no posts, and otherwise it renders a grid of blog post cards
 */
const AllBlogs = ({
  posts,
  currentCategory,
}: {
  posts: any;
  currentCategory?: any;
}) => {
  /**
   * @function renderBlogPostGrid
   * @returns the grid item with the blog post card
   */
  const renderBlogPostGrid = () => {
    return posts.map((post: any, index: number) => {
      return (
        <Grid
          item
          lg={4}
          md={6}
          xs={12}
          className="post-card-grid__item"
          key={`post__${post.node.slug}`}
        >
          <BlogPostCard post={post.node} />
        </Grid>
      );
    });
  };

  return (
    <Fragment>
      {posts.length < 1 && (
        <Grid item xs={12} md={6} className="blogs-category__no-blogs">
          <SkeletonCard
            title="blog"
            category={currentCategory ?? "Blogs"}
            link={urlRoutes.BLOG}
          />
        </Grid>
      )}
      {posts.length >= 1 && (
        <Grid item xs={12} className="blogs-category__grid-item">
          <Grid
            container
            className="post-card-grid"
            sx={{ justifyContent: "space-between" }}
            spacing={4}
          >
            {renderBlogPostGrid()}
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
};

export default AllBlogs;
