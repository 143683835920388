// External Imports
import React, { Fragment, useEffect } from "react";
import { graphql, navigate } from "gatsby";
import { Grid, Box, Pagination, Container, Divider } from "@mui/material";
// Components
import {
  BlogsCallToAction,
  PanelWrapper,
  Breadcrumb,
  DrawerSidebar,
  SEO,
} from "../../../exports/components.exports";
// Config
import { colors } from "../../../exports/assets.exports";
import { ILinkOptionProps } from "../../../exports/types.exports";
import { urlRoutes } from "../../../exports/constants.exports";
import AllBlogs from "./AllBlogs";

/**
 * @constant query for Strapi
 */
export const query = graphql`
  query PostCategory($slug: String!, $skip: Int!, $limit: Int!) {
    allStrapiPost(
      sort: { fields: updated_at, order: DESC }
      filter: { post_category: { slug: { eq: $slug } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          title
          description
          slug
          feature_image {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          post_category {
            slug
            Name
          }
          DynamicContent
          updated_at(fromNow: true)
          created_at(formatString: "YYYY/MM/DD")
          writer {
            name
            picture {
              url
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
    postCategory: strapiPostCategory(slug: { eq: $slug }) {
      Name
      slug
    }
    allStrapiPostCategory {
      edges {
        node {
          slug
          Name
        }
      }
    }
  }
`;

/**
 * @function PostCategory the blog post category component
 * @returns the blog post category
 */
const PostCategory = ({
  data,
  pageContext,
}: {
  data: any;
  pageContext: any;
}) => {
  const posts = data.allStrapiPost.edges;
  const { Name: categoryName, slug: categorySlug } = data.postCategory;
  const postCategories = data.allStrapiPostCategory.edges;
  const { currentPage, numPages } = pageContext;

  const seo = {
    metaTitle: `Swiftcase - ${categoryName} Category Blog Posts  - Page ${currentPage}`,
    metaDescription: `A paginated list of all of Swiftcase's ${categoryName}  category blog posts from over the last few years.`,
  };

  /**
   * @function handlePaginationChange
   * @param value the page number to navigate to
   * @returns the navigation from gatsby
   */
  const handlePaginationChange = (_: any, value: number) => {
    if (value === 1) {
      navigate(`${urlRoutes.BLOG}/${categorySlug}/`);
    } else {
      navigate(`${urlRoutes.BLOG}/${categorySlug}/${value}/`);
    }
  };

  /**
   * @function renderOptions
   * @returns the list of links for the drawer sidebar
   */
  const renderOptions = () => {
    const dropdownOptions: Array<ILinkOptionProps> = [];

    postCategories.map((category: any) =>
      dropdownOptions.push({
        href: `${urlRoutes.BLOG}/${category.node.slug}`,
        text: category.node.Name,
      })
    );

    return dropdownOptions;
  };

  return (
    <Fragment>
      <SEO seo={seo} />
      <BlogsCallToAction />
      <Box sx={{ backgroundColor: colors.swiftcaseWebsiteLightGrey }}>
        <Container
          maxWidth="lg"
          sx={{ backgroundColor: colors.swiftcaseWebsiteLightGrey }}
        >
          <Box
            sx={{
              display: "inline-flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              marginY: "2rem",
            }}
          >
            <Breadcrumb
              primary={{ text: "Blogs", href: urlRoutes.BLOG }}
              currentPage={categoryName}
            />
            <Box>
              <DrawerSidebar listItems={renderOptions()} />
            </Box>
          </Box>
        </Container>
        <Divider />
        <PanelWrapper
          contentWidth="lg"
          minHeight="25vh"
          backgroundColor={colors.swiftcaseWebsiteLightGrey}
        >
          <Grid
            container
            className="post-card-grid"
            sx={{
              justifyContent: posts.length !== 0 ? "flex-start" : "center",
            }}
            spacing={4}
          >
            <AllBlogs posts={posts} currentCategory={categoryName} />

            <Grid
              item
              className="article-grid__grid-item"
              xs={12}
              sx={{ marginTop: "2rem" }}
            >
              <Pagination
                showFirstButton
                showLastButton
                count={numPages}
                page={currentPage}
                boundaryCount={2}
                onChange={handlePaginationChange}
                className="articles-grid__pagination"
                sx={{
                  width: "fit-content",
                  marginX: "auto",
                }}
              />
            </Grid>
          </Grid>
        </PanelWrapper>
      </Box>
    </Fragment>
  );
};

export default PostCategory;
